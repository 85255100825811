import React from "react";
import { Layout } from "../layout";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

export const About = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const AboutUs = (
    <>
      <p>
        <strong>BelloCare</strong> is a premier information sharing;
        ‘’trado-tech’’ empowerment & payment system, that was designed by the
        Kogi state government to promote unity and productivity and provide
        equal opportunity in wealth creation and distribution to the grassroot
        as a unique approach to socio-economic development of Kogites and
        beyond.
      </p>

      <p>
        Since the inception of tenure as the Kogi state government, our goal has
        always been to increase the quality of life of kogites, which is
        determined by the security and socio economic prosperity of people
        within the state, in the best way possible. Seven (7) years down the line,
        through policy reforms, innovations and infrastructure, we have achieved
        giant strides in this journey. From trampling insecurity, to trippiling
        the state’s IGR from 6.7 BN in 2015 to 17.5 BN by 2020 (NBS) and
        attracting some of Africa’s biggest companies; Dangote and Unicane.
      </p>

      <p>
        Despite the achievements so far, we are still far from maximising the
        full potential of the state's natural and human resources to have it
        reflect in the lives of kogites and beyond. Factors like disunity,
        unproductivity, unequal opportunity in wealth creation and distribution
        to the grassroot, and paradigm shift in the world economy, reflect in
        the unemployment rate (39% at 2020- NBS) of the state. This is why our
        current driving goal is to enable young people (the working population),
        unlock their limitless potential that would not only provide them
        financial success but also enable them to compete globally.
      </p>

      <p>
        The global economy as we know it has aligned with a new world order,
        having experienced a paradigm shift as a consequence of recent global
        happenings; pandemics, lockdowns, wars and the likes. While other
        countries have to deal with issues like inflation, insecurity has sadly
        been the ultimate nemesis we have to bear. The unforeseen circumstances
        of these global events, threw Nigeria's economic state into instability
        and as an implication, ignited the dangers of pre-existing problems of
        disunity, unproductivity, inequality in wealth distribution, into an
        enraging widespread “youth redundancy”, which has a rippling effect of
        birthing a chain of problems, ergo nigeria’s security state.{" "}
        <button className="dark-blue-text" onClick={handleOpen}>
          Read more...
        </button>
      </p>
    </>
  );

  const Readmore_About = (
    <>
      <p>
        The new world order requires industries to become innovative in their
        approach and theus, in recent times, there has been an expulsion of new
        technological ideas erupting from different parts of the world, in an
        attempt to disrupt modus operandi of different industries. A notable
        mention is Mark Zuckerberg’s Metaverse. As have seen in the past,
        countries who refuse to compete on the global scale often get wiped out.
      </p>

      <p>
        For Nigeria to compete, she will have to fix her problem of youth
        redundancy by educating, re-engineering the psyche and exposing the
        younger generation to the opportunities out there.
      </p>

      <p>
        Kogi state being the middle belt that holds the north and south
        together, and also due the diverse and youth dominated nature of the
        state, we are sorely affected. Even after pulling the state resources
        together to gain control of security within the state, youth redundancy
        as a result of global economic hits absorbed by private companies, is
        still a consequential matter.
      </p>

      <p>
        It is for this reason, that we the kogi state government, took it upon
        ourselves to combine technology, tradition and sincerity of purpose, to
        create a unique information sharing, Empowerment and payment system
        equipped with a transparent mechanism (<strong>BelloCare</strong>), to
        identify the less-privileged, people with special needs, talent hunts
        and discovery, mentorship, and access to funds as a deliberate plan to
        promote unity, productivity,and provide equal opportunity in wealth
        creation and distribution to the grassroots, from the comfort of homes.
        Thereby fostering socio-economic development of kogites and beyond.
      </p>

      <p>
        <strong>BelloCare</strong> Empowerment system We have identified that
        many young Nigerians in need seem not to benefit from the preexisting
        empowerment system. <strong>BelloCare</strong> empowerment system was
        designed to give equal opportunity to all who deserve one; People with
        special needs, young people with talent and potential who are unheard,
        unseen and untapped in Kogi state. Our system is designed using
        trado-tech to identify hidden talents and foster development of those
        talents by providing mentorship and access to funds from the comfort of
        mobile devices.
      </p>

      <p>
        <strong>BelloCare</strong> Payment System Our unique payment system
        provides convenience, timeliness and fulfilment in the process of funds
        delivery. Thereby ensuring that factors like distance and third-party
        negative influence, do not hinder the flow of money to the pockets of
        those who need it. <strong>BelloCare</strong> payment system simplifies
        both salary payments and traditional transactions; deposit, withdrawal,
        transfer savings, etc, making it accessible to the grassroot. With{" "}
        <strong>BelloCare</strong>, those in the rural areas have access to the
        same financial opportunities that those in the urban areas have.
      </p>

      <p>
        <strong>BelloCare</strong> Information Sharing System Communication is
        bound to happen for true socio-economic prosperity to occur. One of the
        major reasons why disputes have often erupted in different parts of the
        country, is due to the absence of a system of communication,
        deliberately designed for the purpose of intrapersonal communication
        among different tribes and religions to foster cross pollination of
        ideas and strengthening of the “Nigerian Bond”.{" "}
        <strong>BelloCare</strong> is designed to be an avenue for the
        continuous unification of Kogites.
      </p>

      <p>
        The combination of all into one system, using tradition and technology
        is one of the things that make <strong>BelloCare</strong> unique.
        Designed to disrupt the modus operandi of Kogi’s system of empowerment
        and payment, we intend to gear the direction of Nigeria towards a path
        that works, by serving as an icon of socio-economic growth for Nigeria.
        As the centre of the nation that holds the north and the south, it is
        our wish to set the pace of real economic growth in the two major
        regions.
      </p>
    </>
  );

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="absolute flex justify-center m-auto w-fit"
      >
        <Box className="bg-gray-200 rounded-lg shadow-2xl drop-shadow-2xl p-6 lg:w-[50%] text-justify space-y-4 w-[80%] left-[10%] top-[10%]">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="p-1 relative dark-blue-text font-bold"
          >
            More about BelloCare
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            className="lg:max-h-[600px] max-h-[500px] overflow-y-auto"
          >
            {Readmore_About}
          </Typography>
        </Box>
      </Modal>
      <Layout>
        <section className="container mx-auto px-4 lg:py-20 md:py-10 pt-20 pb-10">
          <div
            className="justify-center items-center grid lg:grid-cols-2 grid-cols-1"
            id="vision"
          >
            <div className="lg:border-0 border-b-2 border-[#f98c19]">
              <h1 className="dark-blue-text lg:text-8xl md:text-6xl sm:text-6xl text-6xl font-black text-center lg:h1-20">
                Vision
              </h1>
            </div>

            <div className="text-justify text-2xl leading-relaxed lg:py-10 py-6 lg:pl-5 lg:border-l-2 border-[#F98C19] lg:block px-2 break-normal">
              A state owned socio-economically simplified empowerment, payment
              and insurance system, that promotes unity, well-being,
              productivity, and provides equal opportunity in wealth creation
              and distribution to the grassroots.
            </div>
          </div>

          <div
            className="justify-center items-center grid lg:grid-cols-2 grid-cols-1 lg:py-20"
            id="mission"
          >
            <div className="text-justify text-2xl leading-relaxed lg:py-10 py-6 lg:pr-5 lg:border-r-2 border-[#F98C19] lg:block px-2 break-normal">
              Bridge the gap between the rich and the poor, by combining
              lending-edge technology, tradition and sincerity of purpose to
              create a unique information sharing transparent mechanism, that
              will sincerely identify the less-privileged, people with special
              needs, discover and hunt talents, and provide access to funds from
              the comfort of homes.{" "}
            </div>

            <div className="lg:border-0 border-b-2 border-[#f98c19] flex flex-col">
              <h1 className="dark-blue-text self-center lg:text-8xl md:text-6xl sm:text-6xl text-6xl font-black text-center md:order-last sm:order-first order-first lg:h1-20 ">
                Mission
              </h1>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4" id="about">
            <h1 className="dark-blue-text lg:text-8xl md:text-6xl sm:text-5xl text-4xl font-black text-center lg:px-96 px-20">
              <div className="border-b-2 border-[#f98c19]">About Us</div>
            </h1>

            <div className="text-justify text-2xl leading-relaxed lg:block px-2 space-y-6">
              {AboutUs}
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};
