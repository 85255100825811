import React, { Fragment } from "react";
import { Nav } from "./navbar";
import { Footer } from "./footer";

export const Layout = (props) => {
    return (
        <Fragment>
            {props.children}
        </Fragment>
    );
};

export { Nav, Footer };