import React, { Fragment, useState } from "react";
import { HashLink } from "react-router-hash-link";
import logo from '../assets/logo/coloredlogo.png';
import logoText from '../assets/logo/logo-text.png';
// import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'

export const Nav = () => {
  const [nav, setnav] = useState(false);

  const handleNav = () => {
    setnav(!nav);
  }
// console.log(window.location.pathname)
  return (
    <Fragment>
      
        <nav className={window.location.pathname === '/terms-of-service' || window.location.pathname === '/privacy-policy'? 'bg-gray-100 bg-opacity-80 shadow-lg sticky top-0 z-50 lg:block hidden':'bg-gray-100 shadow-lg sticky top-0 z-50'}>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-4">
            <div className="relative flex items-center justify-between h-20">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* <!-- Mobile menu button--> */}
                <button onClick={handleNav} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                  <span className="sr-only">Open main menu</span>
                  {!nav ?
                    <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    :
                    <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  }
                </button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <HashLink to="/#" smooth>
                  <div className="flex-shrink-0 flex items-center">
                    <img className="block lg:hidden h-8 w-auto" src={logo} alt="Koopays" />
                    <img className="hidden lg:block h-14 w-auto" src={logo} alt="Koopays" />
                    <img className="hidden lg:block h-10 w-auto" src={logoText} alt="Koopays" />
                  </div>
                </HashLink>
              </div>

              <div className="hidden sm:block sm:ml-6 ml-auto p-4">
                <div className="flex space-x-4">
                  <HashLink exact="true" to="/#mission" activeclassname="dark-orange-text" className="dark-blue-text hover:bg-gray-400 hover:text-white px-3 py-2 rounded-md text-sm font-medium" smooth>Mission</HashLink>

                  <HashLink exact="true" to="/#vision" activeclassname="dark-orange-text" className="dark-blue-text hover:bg-gray-400 hover:text-white px-3 py-2 rounded-md text-sm font-medium" smooth>Vision</HashLink>

                  <HashLink exact="true" to="/#about" activeclassname="dark-orange-text" className="dark-blue-text hover:bg-gray-400 hover:text-white px-3 py-2 rounded-md text-sm font-medium" smooth>AboutUs</HashLink>

                  <HashLink exact="true" to="/#feeds" activeclassname="dark-orange-text" className="dark-blue-text hover:bg-gray-400 hover:text-white px-3 py-2 rounded-md text-sm font-medium" smooth>Feeds</HashLink>

                  <HashLink exact="true" to="/#contact" activeclassname="dark-orange-text" className="dark-blue-text hover:bg-gray-400 hover:text-white px-3 py-2 rounded-md text-sm font-medium" smooth>Contact</HashLink>

                </div>
              </div>
            </div>
          </div>

          {/* <!-- Mobile menu, show/hide based on menu state. --> */}
          <div className={!nav ? 'fixed left-[-100%]' : 'ease-in-out duration-900'} id="mobile-menu">
            <ul className="px-2 pt-2 pb-3 space-y-1">
              <li className="dark-blue-text px-3 py-2 text-sm font-medium hover:bg-gray-400 hover:text-white border-b border-gray-400">
                <HashLink to="/#mission" smooth >Mission</HashLink>
              </li>
              <li className="dark-blue-text px-3 py-2 text-sm font-medium hover:bg-gray-400 hover:text-white border-b border-gray-400">
                <HashLink to="/#vision" smooth>Vision</HashLink>
              </li>

              <li className="dark-blue-text px-3 py-2 text-sm font-medium hover:bg-gray-400 hover:text-white border-b border-gray-400">
                <HashLink to="/#about" smooth>AboutUs</HashLink>
              </li>

              <li className="dark-blue-text px-3 py-2 text-sm font-medium hover:bg-gray-400 hover:text-white border-b border-gray-400">
                <HashLink to="/#feeds" smooth>New Feeds</HashLink>
              </li>

              <li className="dark-blue-text px-3 py-2 text-sm font-medium hover:bg-gray-400 hover:text-white border-b border-gray-400">
                <HashLink to="/#contact" smooth>Contact</HashLink>
              </li>
            </ul>
          </div>

        </nav>
    </Fragment>
  );
}