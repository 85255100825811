import React from "react";
import * as FaIcons from 'react-icons/fa'

export const AppStoreButton = () => {
    return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a href="https://apps.apple.com/ng/app/bellocare/id6444568955" target="_blank" className="bg-gradient-to-t from-[#012a4a] via-[#012a4a] to-[#012a4a] text-[#f6f6f6] px-4 py-1 rounded-2xl grid grid-flow-col auto-cols-max lg:space-x-4 hover:from-transparent hover:border-[#012a4a] hover:border-2 hover:text-[#012a4a] hover:border-t-[#59899b]" rel="noreferrer">
            <FaIcons.FaApple className="h-[40px] w-[40px] my-auto"/>
            <div>
                <h6 className="text-xs text-left font-medium">Download on the </h6>
                <h1 className="lg:text-xl font-semibold">AppStore</h1>
            </div>
        </a>
    )
}
export const PlayStoreButton = () => {
    return (
        <a href="https://play.google.com/store/apps/details?id=com.tratrust.bellocare" target="_blank" className="bg-gradient-to-t from-[#012a4a] via-[#012a4a] to-[#012a4a] text-[#f6f6f6] px-4 py-1 rounded-2xl grid grid-flow-col auto-cols-max lg:space-x-4 hover:from-transparent hover:border-[#012a4a] hover:border-2 hover:text-[#012a4a] hover:border-t-[#59899b]" rel="noreferrer">
            <svg className="h-[33px] w-[33px] my-auto" viewBox="0 0 33 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.7653 22.8308C27.7751 20.6291 30.815 18.9514 31.077 18.821C31.9152 18.3752 32.7808 17.1955 31.077 16.2791C30.5269 15.991 27.5652 14.3654 23.7653 12.2693L18.4976 17.5892L23.7653 22.8308Z" fill="#FFD900" />
                <path d="M18.4976 17.5892L1.72449 34.494C2.11817 34.5461 2.56268 34.4418 3.08672 34.1537C4.18694 33.5515 15.85 27.1822 23.7653 22.8322L18.4976 17.5892Z" fill="#F43249" />
                <path d="M18.4976 17.5892L23.7654 12.2954C23.7654 12.2954 4.26652 1.65431 3.08679 1.02598C2.64227 0.762662 2.14431 0.684447 1.69849 0.762662L18.4976 17.5892Z" fill="#00EE76" />
                <path d="M18.4976 17.5892L1.6985 0.762695C1.01673 0.920428 0.440552 1.52268 0.440552 2.75455V32.5021C0.440552 33.6297 0.886373 34.4418 1.72457 34.52L18.4976 17.5892Z" fill="#00D3FF" />
            </svg>

            <div>
                <h6 className="text-xs text-left font-medium">Get it on </h6>
                <h1 className="lg:text-xl font-semibold">Google Play</h1>
            </div>
        </a>
    )
}