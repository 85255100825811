import React, { Fragment } from "react";
import { Layout } from "../layout";
import { BsChatDots, BsFillBookmarkDashFill } from 'react-icons/bs';
import * as FaIcons from 'react-icons/fa'
import axios from '../api/axios'
import TextTruncate from 'react-text-truncate'; // recommend
import { FormatDateTime } from "../components/formatDate";
import { TableSpinner } from "../components/spinner";

const GET_FEEDS = "/feed"

export const Feeds = () => {
    const [feeds, setFeeds] = React.useState()



    React.useEffect(() => {
        getFeeds();
    }, [])

    const getFeeds = async () => {
        const response = await axios({
            method: 'get',
            url: GET_FEEDS,
        })
// console.log(response.data)
            setFeeds(response?.data?.data.page_data)

    };

    return (
        <Fragment>
            <Layout>
                <section id="feeds" className="shadow-inner py-10">
                    <div className="container mx-auto md:flex md:justify-between">
                        <h1 className="dark-blue-text lg:text-5xl md:text-5xl sm:text-5xl text-5xl font-extrabold text-center">Feeds</h1>
                        {/* <div className="flex justify-center pt-5">
                            <button className="dark-blue-bg hover:bg-gray-50 hover:text-sky-900 py-1 px-4 shadow-lg rounded-l-xl">Top Feeds</button>
                            <button className="light-gray-bg hover:bg-blue-100 py-1 px-4 shadow-lg rounded-r-xl">Recent Feeds</button>
                        </div> */}
                    </div>


                    <div className="container relative mx-auto mt-10 w-full space-y-3 max-h-screen overflow-y-auto">
                        {!feeds ? <TableSpinner/> : null}
                        {feeds?.map((item, index) => {
                            return (
                                item.status.status === "PUBLISHED" ?
                                <div key={index} className="w-full lg:h-60 sm:h-30 px-10 py-5 shadow-inner drop-shadow-lg bg-white">
                                    <div className="flex h-full lg:border-l-4 lg:px-4 border-gray-300 rounded-4xl lg:rounded-md ">

                                        <div className="flex-none flex-col justify-between w-1/5 h-full rounded-2xl lg:rounded-md">
                                            <img src={item.media[0].path} className="h-[85%] w-full rounded-2xl lg:rounded-md" alt="logo" />
                                            
                                            {/* <span className="flex space-x-3 mt-2">
                                                <span className="lg:flex hidden">
                                                    <FaIcons.FaRegThumbsUp size={20} className="text-green-500 lg:block" />
                                                    <h6 className="dark-blue-text font-bold pl-1 text-lg">{item.like_by?.length || 0}</h6>
                                                </span>
                                                <span className="lg:flex hidden">
                                                    <FaIcons.FaRegThumbsDown size={20} className="text-red-500 lg:block" />
                                                    <h6 className="dark-blue-text font-bold pl-1 text-lg">{item.dislike_by?.length || 0}</h6>
                                                </span>
                                                <span className="lg:flex hidden">
                                                    <BsChatDots size={20} className="light-orange-text lg:block" />
                                                    <h6 className="dark-blue-text font-bold pl-1 text-lg">{item.comment?.length || 0}</h6>
                                                </span>
                                            </span> */}
                                        </div>

                                        <article className="grow flex-col pl-4 w-40">
                                            <header className="dark-blue-text lg:text-3xl md:text-2xl sm:text-lg xs:text-md font-bold lg:order-1 order-2">{item.title}</header>
                                            <p className="hidden lg:block lg:order-3 text-justify">
                                                <TextTruncate
                                                    line={4}
                                                    element="span"
                                                    truncateText=" Readmore…"
                                                    text={item.content}
                                                />
                                            </p>
                                            <p className="dark-blue-text lg:text-xl text-sm lg:order-4 order-1">{item.category}</p>
                                            <span className="flex justify-start text-xs lg:hidden order-5">
                                                <h6>{FormatDateTime(item.created_at)}</h6>
                                            </span>
                                        </article>

                                        <div className="flex flex-col justify-between w-1/3">
                                            {/* <span className="flex justify-end">
                                                <BsFillBookmarkDashFill size={15} className="light-orange-text" />
                                            </span> */}

                                            <span className="flex justify-end">
                                                <h6 className="lg:block hidden">{FormatDateTime(item.created_at)}</h6>

                                                {/* <span className="space-y-1">
                                                    <span className="flex lg:hidden">
                                                        <FaIcons.FaRegThumbsUp size={18} className="text-green-500 lg:block" />
                                                        <h6 className="dark-blue-text font-bold pl-1 text-md">{item.like_by?.length || 0}</h6>
                                                    </span>
                                                    <span className="flex lg:hidden">
                                                        <FaIcons.FaRegThumbsDown size={18} className="text-red-500 lg:block" />
                                                        <h6 className="dark-blue-text font-bold pl-1 text-md">{item.dislike_by?.length || 0}</h6>
                                                    </span>
                                                    <span className="flex lg:hidden">
                                                        <BsChatDots size={18} className="light-orange-text lg:block" />
                                                        <h6 className="dark-blue-text font-bold pl-1 text-md">{item.comment?.length || 0}</h6>
                                                    </span>
                                                </span> */}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            )
                        })}


                    </div>

                </section>
            </Layout>
        </Fragment>
    )
}