import React, { Fragment } from "react";
import { Nav } from "./layout/navbar";
import { Footer } from "./layout";
import { Home, About, Feeds } from "./sections";
import { HashLink } from "react-router-hash-link";
import { Route, Routes } from "react-router-dom";
import { PrivacyPolicy } from "./sections/privacy-policy";
import { TermsOfService } from "./sections/terms-of-service";

function App() {
  return (
    <Fragment>

      <Nav />
      {/* <Home /> */}
      {/* <About /> */}
      {/* <Feeds /> */}

      <Routes>
        <Route exact index element={<Home />} />
      </Routes>
      <Routes>
        <Route exact index element={<About />} />
      </Routes>
      <Routes>
        <Route exact path="/" element={<Feeds />} />
      </Routes>
      <Routes>
        <Route exact index element={
          <div className="flex justify-center mb-4 animate-bounce">
            <HashLink to="/#" className="inline-flex justify-center rounded-xl border border-gray-300 shadow-sm px-8 py-2 bg-white text-sm font-medium light-orange-text hover:bg-gray-50" smooth>
              <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.37588 0H12.1109V1.78918H1.37588V0ZM2.00835 10.4729L5.84882 6.63247V14.3134H7.638V6.63247L11.4785 10.4729L12.7434 9.20799L6.74341 3.20799L0.743408 9.20799L2.00835 10.4729Z" fill="#F98C19" />
              </svg>
              &nbsp;
              Back to Top
            </HashLink>
          </div>
        }
        />
      </Routes>
      {/* <Footer /> */}
      <Routes>
        <Route exact index element={<Footer />} />
      </Routes>
      <Routes>
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <Routes>
        <Route exact path="/terms-of-service" element={<TermsOfService />} />
      </Routes>

    </Fragment>
  );
}

export default App;
