import React from 'react'

export const PrivacyPolicy = () => {
    return (
        <>
            <article className='text-justify lg:py-10 lg:px-20 px-8 py-5 space-y-6'>
                <h1 className='text-2xl text-center dark-blue-text'><strong>Privacy Policy</strong></h1>
                <header><strong className='text-xl dark-blue-text'>Introduction</strong></header>
                <p>
                    <strong>BelloCare</strong> is a state-owned socio-economically simplified empowerment and payment system, that promotes unity, productivity and provides equal opportunity in wealth creation and distribution to the grassroots.
                    For contact purposes on any matter concerning this Privacy Policy and on how your Personal Information is been used, please contact us on info@bellocare.ng
                </p>
                <header><strong className='text-xl dark-blue-text'>Overview</strong></header>
                <p>
                    This Privacy Policy describes your privacy rights regarding the way, the time and the means with which we collect, use, store, share and protect your personal information across the website, Apps, software application, email notifications and tools, notwithstanding your usage.
                    This Privacy Policy is applicable to all of the website, software applications (“Apps”) and/or payment platforms offered by <strong>BelloCare</strong> or affiliated companies collectively referred to as <strong>BelloCare</strong> “services”.
                    “<strong>BelloCare</strong>”, “we” “us” or our” in this privacy statement means the <strong>BelloCare</strong> entity that is responsible for processing your personal information.
                </p>
                <header><strong className='text-xl dark-blue-text'>Definition</strong></header>
                <p>
                    Cookies: A cookie is a transferrable data file sent to your computer or mobile device, that gives us access to remember your log-in details, IP addresses, web traffic, browser type and version, device details, number of times you visit the site, exact date and time of each visit. Cookies allow websites and applications to store your preferences in order to present contents, options or functions that are specific to you.
                    Personal Information: Personal Information can be classified as any means which a person is identified by, this includes, but not limited to email address, Bank Verification Number (BVN), payment card, taxpayer identification number (TIN), password, Government Issued IDs. It may also include anonymous information that is linked to you, for example, your internet protocol (IP), log-in information, address, location, device or transaction data.
                    Sites: any platform such as mobile applications, websites, social medias, etc.
                    User: a person who uses the services or gains access to the site and agrees to use the services of <strong>BelloCare</strong>.
                </p>
                <header><strong className='text-xl dark-blue-text'>Objective</strong></header>
                <p>
                    This Privacy Policy statement provides for all whose personal information is held by us, as to the type of information we collect, to the purpose of such collection, and to the third parties with whom we may share such data with and the steps which is taken to protect the data.
                </p>
                <header><strong className='text-xl dark-blue-text'>Our Privacy Principle</strong></header>
                <p>
                    Below are the core principles of <strong>BelloCare</strong>:
                    To Empower Kogites: it is <strong>BelloCare</strong> prerogative for you to be in charge of your personal information, for you to make your choices voluntarily.
                    <ul className='list-disc'>
                        <li>
                            To keep and secure personal information: Here at <strong>BelloCare</strong> information trusted with us is protected always. Security measures are put in place that protects your personal information.
                        </li>
                        <li>
                            To be transparent and to educate users: For you to know what personal information is, how we collect personal information, for what purpose and how we secure personal information;
                        </li>
                        <li>
                            To collect and store personal information on the “need to collect” basis: <strong>BelloCare</strong> collects information that is relevant to the services it renders to you. We will never collect information that isn’t needed for the betterment of our services to you, hence the measures we have in place.
                        </li>
                        <li>
                            To abide by local laws: <strong>BelloCare</strong> is a trado-tech empowerment and payment system with local privacy expertise Our privacy practices are bound by the governing laws of Nigeria. Specific privacy notices may apply to some of our products and services. Please visit the webpage or digital asset of the specific product or service to learn more about our privacy and information practices in relation to that product or service;
                        </li>
                    </ul>

                </p>

                <header><strong className='text-xl dark-blue-text'>Content</strong></header>
                <ol className='list-decimal'>
                    <li>Personal Information we may collect about you;</li>
                    <li>How Personal Information is gathered and reason as to having such informatio</li>
                    <li>How your Personal Information might be used</li>
                    <li>Data security and retention</li>
                    <li>Disclosing your Personal Information;</li>
                    <li>Marketing</li>
                    <li>Your data protection rights and choices</li>
                    <li>Cookies</li>
                    <li>Use by Minors</li>
                    <li>Updates to our privacy policy</li>
                    <li>Contact Us</li>
                </ol>

                <ol className='list-decimal'>
                    <li>
                        Personal Information We May Collect About You
                        We may collect, use, process, store, or transfer personal information such as:
                        •	Identity Data: Full name, government issued identity, date of birth this are types of data that verifies you and grants you access to the services we provide.
                        •	Contact Data: This data is necessary for contact purposes, such data includes; address, email, phone no, billing address.
                        •	Identification documents: this includes but not limited to government issued identities, passport photograph and any other registration information you may provide to prove you are eligible to use our services and in compliance with regulatory requirements on Know Your Customer (KYC).
                        •	Log/Technical information: <strong>BelloCare</strong>, when accessed, the servers immediately store information that your browser sends whenever you visit a website, this includes but not limited to, duration of the time spent on each site you visit, links accessed by you, log in information, device location etc.
                        •	Financial Data: Banking related information which includes BVN, account numbers, date, amount of transaction, receiver details, data from financial institution, and other merchants.
                        •	Transactional Data: These are information relating to a payment when you as a merchant (using one or more of our payment processing services) or as a customer, are using our products or services;
                        •	Marketing and Communications Data: This includes both a record of your decision to subscribe or to withdraw from receiving marketing materials from us or from our third parties.
                        •	Records of your discussions with us, if we contact you and if you contact us.
                        This Privacy Policy pertains to <strong>BelloCare</strong> services only. Sites and links displayed within our various services are independent of this policy. <strong>BelloCare</strong> has no power over third-party websites and won’t be held responsible for any privacy statement by them.
                    </li>
                    <li>
                        How Personal Information is gathered and reason as to having such information

                        All Personal Information held by <strong>BelloCare</strong> is provided by you, when you:
                        •	Register for a <strong>BelloCare</strong> Account;
                        •	Make use of any <strong>BelloCare</strong> services
                        •	Contact the customer support team;
                        •	Fill any of our forms online
                        •	Contact us;
                        Your Personal Information is processed on this lawful basis:
                        •	On contractual basis:  Services cannot be provided for you without us collecting your personal information.
                        •	Your Express consent:  This is you agreeing to let us collect your personal information when you use our services.
                        •	We are legally bound: To always be complaint with all necessary financial regulations ranging from Anti-Money Laundering and Counter Terrorist Financing Laws, your personal information is crossed off your identity in order to protect you against fraud.
                    </li>
                    <li>
                        How your Personal Information might be used
                        •	To facilitate the creation and management of any account you have with us, confirm your identity, get back to you on inquiries and expedite the provision of our services to you.
                        •	To help in the processing of your payment transactions
                        •	To guard you against any form of fraud, liabilities, and unauthorized transactions.
                        •	Provide, administer and communicate with you about products, services, offers, programs and promotions of <strong>BelloCare</strong> financial institutions, merchants and partners;
                        •	As may be required by applicable laws and regulations, including for compliance with Know Your Customers and risk assessment, Anti-Money Laundering, anti-corruption and sanctions screening requirements, or as requested by any judicial process, law enforcement or governmental agency having or claiming jurisdiction over <strong>BelloCare</strong> To use data analytics to improve our website, products or services, user experiences and to optimize service.
                    </li>
                    <li>
                        Data Security and Retention
                        At <strong>BelloCare</strong> the security of your Personal Information is important. Our utmost priority is protecting the information gathered. We protect against loss, theft unauthorized access, misuse.
                        Other measures taken to protect your information include securing the servers, data encryption and allowing employees access in order to carry out their duties.
                        Always ensure your password is protected and not shared with anybody.
                        Information transmitted online isn’t entirely secure, we at <strong>BelloCare</strong> ensure that we protect and maintain your Personal Information. There’s no guarantee that information put out online is secure, but be rest assured that we take every necessary step in ensuring that your information is protected.
                        Your Personal Information is only retained by us only for the duration of time you use our services, your information is still preserved with us for a period of time even after your account is disabled for the purpose of monitoring for unusual activities like fraud. This retention would not pass the stipulated time permitted by applicable law.
                    </li>
                    <li>
                        Disclosure of Personal Information
                        <strong>BelloCare</strong> may share your Personal Information with third parties which includes but not limited to employees, officers, service providers, subcontractors, only in the following circumstances will we share your Personal Information:
                        •	With your express consent:  Third parties that you authorize is given access to your personal information. Information that is shared with a third party is bound by the third party’s privacy policy <strong>BelloCare</strong> indemnifies itself from any breach that arises from such authorization.
                        •	Your Personal Information is also shared with trusted allies, in the course of doing business, in order for such information to be processed on behalf of us.  These trusted allies process this information based on our instructions and guidelines and in compliance with our policies.
                        •	Your Personal Information can be accessed also; for the purpose of an investigation of any violation, security threat, prevent any fraudulent activities, protect <strong>BelloCare</strong> and its users.
                        •	If for any reason <strong>BelloCare</strong> is subject to a dissolution, acquisition of any sort, a formal notice would be sent to you, formally notifying you of the transfer of your personal information.
                    </li>
                    <li>
                        Marketing
                        Your Personal Information is used to contact you, make you aware of products, services, conduct market surveys, etc.  This is optional, as you can choose not to be contacted by us. In choosing not to contact with us, no marketing content will be sent to you, but every transactional and service message would still be sent across to you.
                    </li>
                    <li>
                        Your Data Protection Rights and Choices
                        As a user of our services, you are entitled to certain rights in regards to your Personal Information:
                        •	To be informed at all times.
                        •	Right to access your personal information when you sign into your account, the right to ask us to erase any copy of your personal information held by us (this right is limited).
                        •	Right to make corrections to your personal information.
                        •	Right to ask us to stop sending marketing contents to you.
                        Your Personal Information cannot be processed without your express consent, this consent can be rescinded at any time by you.
                        At any point you wish to exercise any of the rights, you can contact us for assistance.
                    </li>
                    <li>
                        Cookies
                        The use of cookies is to always recognize you, improve on the services we render to you and tailor it to suit each individual specific needs.
                        When you choose to disable cookies, bear in mind that the it may create limitation on your usage of the website.
                    </li>
                    <li>
                        Minor
                        <strong>BelloCare</strong> website and services are restricted to age eighteen (18) and above. We will never consciously collect Personal Information of people who fall below the consent age.
                        At any point you are aware that we have collected a minors Personal Information, please contact us, so that proper actions can be taken.
                    </li>
                    <li>
                        Updates to our privacy policy
                        This Privacy Policy is subject to change in order to implement new updates to our services. Changes made will automatically appear on this page, if such changes would have a personal effect on you, you will be notified via mail or online.  Continual use of the services of <strong>BelloCare</strong> serves as consent to all changes on this policy.
                    </li>
                    <li>
                        Contact us:
                        For additional information, questions, complaints regarding this policy should all be directed to
                        This Privacy Policy was created August 2022.
                    </li>
                </ol>
            </article>

        </>
    )
}
