import React from "react";

export const TermsOfService = () => {
  return (
    <>
      <article className="text-justify lg:py-10 lg:px-20 px-8 py-5 space-y-6">
        <h1 className="text-2xl text-center dark-blue-text">
          <strong>Terms of Service</strong>
        </h1>

        <strong>BACKGROUND</strong>
        <p>
          These Terms of Use shall serve as an agreement between you and
          BelloCare. By creating an account with us you have accepted to our
          Terms and Condition. The use of BelloCare implies that the terms and
          conditions are fully understood and will be adhered to. Violation of
          these Terms and Condition will result to immediate termination from
          the app.
        </p>

        <b>
          If you do not agree to all the Terms and Conditions, please do not
          proceed with any form of registration.
        </b>
        <p>
          This Terms and Conditions of use is between Bello Care and you. Bello
          Care’s obligations to you is detailed in this agreement. Risks
          associated with using the services are also detailed, such risks are
          to be thoroughly considered, because you shall be bound by this
          agreement by using the services provided by Bello Care. Accessing the
          site, you have agreed to the Terms of use.
        </p>
        <p>
          We grant you express right, that is non-transferable, non-exclusive,
          to use all services rendered by Bello Care during the duration of this
          Agreement. This right covers all update, upgrades of Bello Care
          services. When you do not comply with the documentation and any other
          requirements provided by Bello Care, then you will be liable for any
          damage incurred by you, Bello Care and third parties.
        </p>
        <p>
          By using this app, we hereby grant you a revocable, non-exclusive,
          non-transferable license to use Bello Care trademarks used to identify
          our services only for the combined use of our services. In agreeing to
          these terms, you may not at any time during the use of this app assert
          any claim, do anything that may jeopardize the validity of this
          Trademark. Upon expiration of this agreement, you shall cease any
          display, advertise and use of the Trademark.
        </p>
        <br />
        <strong>ACCOUNT OPENING</strong>
        <p>
          To open an Account with BelloCare, you must be at least 18 years of
          age, a Kogi indigene, not having any prior account. BelloCare would
          never intentionally open an account for an individual who does not
          meet up to the necessary requirements above. In accepting to create an
          account with us, you give us the exclusive right to the information
          you provide, to make and receive payments for you. Any decision
          communicated to stop using our service implies that the account you
          hold with us will be closed.
        </p>
        <br />
        <strong>VERIFYING YOUR ACCOUNT</strong>
        <p>
          BelloCare requires you to verify your email address before you can
          proceed to use the services rendered on the App. An OTP is sent to the
          email address provided by the user upon registration, using this OTP
          gives you further access to the services provided by BelloCare.
        </p>
        <br />

        <strong>COMMUNICATION</strong>
        <p>
          English Language shall be the dominant language for communications on
          BelloCare but this will not restrict the use of languages dominate to
          Kogi State for easier communication.
        </p>
        <br />

        <strong>DIGITAL BANK</strong>
        <p>
          All users can see their ledger account balance, available balance,
          transfer, account numbers, barcodes, withdrawals, deposits and
          transfers. Users have the ability to transfer money within the App and
          also to any bank within the list of banks provided. Money transferred
          within the App, the user can either scan a dedicated individuals
          barcode to send money to the owner or through using the individual’s
          MEM Number.
        </p>
        <br />

        <strong>UTILITY</strong>
        <p>
          This aspect of the App allows users to buy airtime, data, recharge
          their electricity and also subscribe for their cable television
          services.
        </p>
        <br />

        <strong>OTHERS</strong>
        <p>
          Every user has a unique referral code, this code can be given to new
          users to complete their registration and a small token is rewarded to
          the referral code owners. Users can see how many users have used their
          codes and how much points they made through referrals. Empowerment;
          users can request for empowerment through this portal. For users to be
          eligible to the empowerments, a PVC verification is required.
        </p>
        <br />

        <strong>WHEN THINGS GO WRONG</strong>
        <p>
          At any point you believe that there is an unauthorized or stressful
          transaction that took place, you are to notify us immediately, for
          proper action to be taken in order to avoid/prevent any financial
          loss. All claims against the app should be made within thirty (30)
          days after such transaction has taken place. If and when no claims
          have been made after the said days, it would be assumed that you
          exonerate us from any claims to the fullest extent of the law.
        </p>
        <br />

        <strong>THIRD PARTY</strong>
        <p>
          Any transaction done with a third party that results in a dispute over
          goods and services purchased by you, we shall bear no liability for
          such transaction.
        </p>
        <br />

        <strong>DATA PROTECTION</strong>
        <p>
          By creating this account, you are granting BelloCare the authority to
          process your personal information. To provide services to you based on
          the information you provide.
        </p>
        <br />

        <strong>DECLARATION</strong>
        <p>
          By agreeing to this, you hereby grant Bello Care the right to use your
          name, logo in our marketing tools.
        </p>
        <p>
          Bello care reserves the right to amend, delete or replace all or any
          of these conditions at any time without prior notice. You shall be
          notified of any changes made to the conditions as soon as is possible,
          failure of such notifications shall not invalidate the changes.
        </p>
        <p>Changes can be made to services rendered without prior notice.</p>
        <p>
          Bello Care reserves the right the terminate your account if we suspect
          you have engaged in any fraudulent activity while using our services.
        </p>
        <p>
          By agreeing to these terms, you indemnify Bello Care of any legal
          actions or otherwise that will crop up from your violation of the
          agreement, falsified information provided upon registration .
        </p>
        <p>
          This Agreement shall be governed by and in accordance with the laws of
          the Federal Republic of Nigeria.
        </p>
      </article>
    </>
  );
};
